.fullmenu-active {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
}

/* .fullmenu-inactive {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
} */

.fullmenu-inactive {
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width:60px !important;
}

.cardPadding{
    padding-left: 0px !important;
}
/* .sideMenu.ant-menu-horizontal > .ant-menu-item-selected{
background-color: #4B5770;
} */
.sideMenu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
 {
    background-color: #4B5770;
    border-radius: 5px !important;
    /* border-bottom-right-radius:4px ; */
    border-bottom:none;
    color: #FFFFFF;
    opacity: 1;
}
.ant-menu-light.ant-menu-item:hover,.ant-menu-light.ant-menu-item-active{
    color: #FFFFFF;
}
.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover{
	color:#4B5770 ;
} 
/* .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover{
    color: #000;
} */